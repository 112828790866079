import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faFacebook, faGithub, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import Link from 'next/link'

const Footer = () => {

    const VERSION = process.env.NEXT_PUBLIC_VERSION;
    const SHA = process.env.NEXT_PUBLIC_SHA;
    //const HOST = process.env.NEXT_PUBLIC_HOST;
    // const version = "v1.0";
    // const host = process.env.name;

    return (
    <footer className="relative bg-blueGray-200 pt-8 pb-6">
    <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
        <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl fonat-semibold text-blueGray-700">Lets connect!</h4>
            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
            Find me on any of these platforms.
            </h5>
            <div className="mt-6 lg:mb-0 mb-6">
            <button className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-3" type="button">
                <a href="https://www.linkedin.com/in/trevse81/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" style={{color: "#0077B5",}} />
                </a>
            </button>
            <button className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-3" type="button">
                <a href="https://github.com/terickson81" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faGithub} size="2x" style={{color: "#171515",}} />
                </a>
            </button>
            <button className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-3" type="button">
                <a href="https://twitter.com/trevse81" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} size="2x" style={{color: "#0F1419",}} />
                </a>
            </button>
            </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
            <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Useful Links</span>
                <ul className="list-unstyled">
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://trevorerickson.com">Home</Link>
                </li>
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://trevorerickson.com/resume">Resume</Link>
                </li>
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://github.com/terickson81">Github Profile</Link>
                </li>
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://trevorerickson.com/projects">Projects</Link>
                </li>
                </ul>
            </div>
            <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Other Resources</span>
                <ul className="list-unstyled">
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://azure.microsoft.com">Microsoft Azure</Link>
                </li>
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://openai.com/">OpenAI</Link>
                </li>
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="https://github.com">GitHub</Link>
                </li>
                <li>
                    <Link className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" href="mailto:terickson81@gmail.com">Contact Me</Link>
                </li>
                </ul>
            </div>
            </div>
        </div>
        </div>
        <hr className="my-6 border-blueGray-300"/>
        <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-6/12 px-4 mx-auto text-center">
                <div className="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © <span id="get-current-year">2024</span>
                <Link href="https://trevorerickson.com" className="text-blueGray-500 hover:text-blueGray-800"> Trevor Erickson</Link>.
                </div>
                <div className="text-xs text-blueGray-500 py-1 font-semibold">                          
                    {VERSION}
                </div>
                <div className="text-xs text-blueGray-500 py-1">                          
                    {SHA}
                </div>
            </div>
        </div>
    </div>
    </footer>
    );
  };
  
  export default Footer;
  