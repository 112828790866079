import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { signOut } from 'next-auth/react'
import Avatar from '@mui/material/Avatar';
import { useSession } from "next-auth/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Profile } from "@/utils/types"
import Link from 'next/link';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { getToken } from 'next-auth/jwt';


export default function ProfileMenu() {

    const { data: session, status } = useSession();
    const email = session?.user?.email;

    const [profile, setProfile] = useState<Profile | null>(null);

    useEffect(() => {
        fetch('/api/profiles/' + email)
            .then(response => response.json())
            .then(data => setProfile(data))
            .catch(error => console.error('Error:', error));
    }, []);

    // console.log("Session", JSON.stringify(session, null, 2))
    // console.log("User Image: ", session?.user?.image)

    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
            return;
            }

            setIsOpen(open);
    };

    return (
        <div>
        <Avatar className="mr-2"
                sx={{ bgcolor: '#346f98', 
                    '&:hover': {
                        transform: 'scale(1.1)',
                        cursor: 'pointer',
                    }
                }}
                alt={session?.user?.name || session?.user?.email || undefined}
                src={session?.user?.image || undefined}
                onClick={toggleDrawer(true)}
        />
        

            <Drawer 
                anchor="right" 
                open={isOpen} 
                onClose={toggleDrawer(false)}
                style={{ width: '15vw' }}
                PaperProps={{ style: { borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' } }}>
                {/* Add the content of the drawer here */}           
                    
                
                <div className="mt-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Avatar className="m-2"                    
                    alt={session?.user?.name || session?.user?.email || undefined}
                    src={session?.user?.image || undefined}/>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '15em' }}>
                        <div className="text-sm font-semibold leading-6 text-gray-900">
                        {session?.user?.name}
                        </div>
                        <div className="text-sm leading-6 text-gray-900">
                        {session?.user?.email}
                        </div>
                        <div className="text-sm leading-6 text-gray-900">
                        {profile?.username}
                        </div>
                    </div>
                    <div  className="m-2" style={{ display: 'flex', justifyContent: 'right' }}>
                        <FontAwesomeIcon 
                            icon={faXmark} 
                            size="2x" 
                            style={{color: "#0077B5", cursor: 'pointer'}} 
                            onClick={toggleDrawer(false)}/>    
                    </div>
                </div>
                <div className="my-2 mx-2">
                    <hr className="my-2 border-blueGray-300"/>
                    <div 
                        className="text-sm m-2 leading-6 text-gray-900" 
                        style={{ display: 'flex', justifyContent: 'left' }}>
                            <Link href="/profile" className="" onClick={toggleDrawer(false)}>
                                 Your Profile
                            </Link>
                    </div>
                    {/* <hr className="my-2 border-blueGray-300"/> */}
                    <div 
                        className="text-sm m-2 leading-6 text-gray-900" 
                        style={{ display: 'flex', justifyContent: 'left' }}>
                        Your Sandbox
                    </div>
                    {/* <hr className="my-2 border-blueGray-300"/> */}
                    <div 
                        className="text-sm m-2 leading-6 text-gray-900" 
                        style={{ display: 'flex', justifyContent: 'left' }}>
                        Settings
                    </div>
                    {profile?.role === 'Admin' && (
                        <div>
                            <hr className="my-2 border-blueGray-300"/>
                            <div 
                                className="text-sm m-2 leading-6 text-gray-900" 
                                style={{ display: 'flex', justifyContent: 'left' }}>
                                <Link href="/admin" className="" onClick={toggleDrawer(false)}>
                                    Admin
                                </Link>
                            </div>
                        </div>
                    )}
                    <hr className="my-2 border-blueGray-300"/>
                    <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <button className="text-sm leading-6 text-gray-900 mb-2" onClick={() => signOut()}>Log Off</button>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}