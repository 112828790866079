import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { GoogleAnalytics } from '@next/third-parties/google'
//https://nextjs.org/docs/pages/building-your-application/configuring/environment-variables

type LayoutProps = {
    children: React.ReactNode;
  };

const Layout: React.FC<LayoutProps> = ({ children }) => {

  const tstId = process.env.NEXT_PUBLIC_TEST_ID || '';
  console.log('tstId = ', tstId);

  const gaId = process.env.NEXT_PUBLIC_GA_ID || '';
  console.log('gaId = ', gaId);

  return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <GoogleAnalytics gaId={gaId} />
        <Header />
          <div className="container mx-auto flex-grow">
            <main style={{ flex: 1 }}>{children}</main>
          </div>
      <Footer />
      </div>


  );
};

export default Layout;
