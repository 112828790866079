import { useRouter } from 'next/router';
import Link from 'next/link'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useSession } from "next-auth/react"
//import { signOut } from 'next-auth/react'
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  XMarkIcon,
  RectangleGroupIcon,
  LightBulbIcon,
  CubeTransparentIcon,
  DocumentTextIcon,
  LinkIcon,
  AcademicCapIcon,
  IdentificationIcon,
  DocumentIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronRightIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import ProfileMenu from '@/components/ProfileMenu';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { getToken } from 'next-auth/jwt';

const nonRoutablePages = ['playground', 'info', 'about', 'clientprops', 'serverprops', 'staticprops'];

const playground = [
    { name: 'Sandbox', description: 'Experiment with different apps and environments', href: '/playground/sandbox', icon: RectangleGroupIcon, newWindow: false },
    { name: 'Projects', description: 'Explore fun and interesting projects', href: '/playground/projects', icon: LightBulbIcon, newWindow: false },
    { name: 'Examples', description: 'View examples of various code, components, layouts', href: '/playground/examples', icon:   CubeTransparentIcon, newWindow: false },
  ]

const info = [
    { name: 'Blog', description: 'My Ramblings', href: '/info/blog', icon: DocumentTextIcon, newWindow: false },
    { name: 'Guides', description: 'Learn stuff', href: '/info/guides', icon: AcademicCapIcon, newWindow: false },
    { name: 'Links', description: 'Useful things', href: '/info/links', icon: LinkIcon, newWindow: false },
]

const about = [
    { name: 'Trevor', description: 'Some interesting bits about me', href: '/about/trevor', icon: IdentificationIcon, newWindow: false },
    { name: 'Resume', description: 'View my resume', href: '/about/resume', icon: DocumentIcon, newWindow: false },
  ]


const examples = [
  { name: 'ServerSideProps', description: 'View examples of ServerSideProp generated pages', href: '/ex/ssp/dt', icon: ChartPieIcon, newWindow: false },
  { name: 'StaticPros', description: 'View examples of StaticProp generated pages', href: '/ex/sp/dt', icon: CursorArrowRaysIcon, newWindow: false },
  { name: 'ClientSideProps', description: 'View examples of StaticProp generated pages', href: '/ex/cp/dt#', icon: FingerPrintIcon, newWindow: false },
]

const contacts = [
  { name: 'YouTube Videos', href: 'https://www.youtube.com/@TrevSE81', icon: PlayCircleIcon },
  { name: 'Contact', href: 'mailto:trevor@trevorerickson.com', icon: PhoneIcon },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Header = () => {

    const { data: session, status } = useSession();
    const router = useRouter();
    const pathSegments = router.pathname.split('/').filter(Boolean);

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-white">
        <nav className="mx-auto flex items-center justify-between p-4 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
                <Link href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Trevor Erickson</span>
                <img className="h-10 w-auto" src="/te-logo-512.png" alt="" />
                </Link>
            </div>
            <div className="flex lg:hidden">
                <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
                >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
                <Popover className="relative">
                    <Popover.Button 
                        className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                        Playground
                        <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4">
                            {playground.map((item) => (
                            <div
                                key={item.name}
                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                            >
                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                </div>
                                <div className="flex-auto">
                                
                                {item.newWindow ? (
                                    <a href={item.href} className="block font-semibold text-gray-900" target="_blank" rel="noopener noreferrer">
                                        {item.name}
                                        <span className="absolute inset-0" />
                                    </a>
                                ) : (
                                    <Link href={item.href} className="block font-semibold text-gray-900">
                                    {item.name}
                                    <span className="absolute inset-0" />
                                </Link>
                                )}
                                <p className="mt-1 text-gray-600">{item.description}</p>
                                </div>
                            </div>
                            ))}
                        </div>                
                        </Popover.Panel>
                    </Transition>
                </Popover>

                <Popover className="relative">
                    <Popover.Button 
                        className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                        Info
                        <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4">
                            {info.map((item) => (
                                <div
                                    key={item.name}
                                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                >
                                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                    </div>
                                    <div className="flex-auto">
                                    
                                    {item.newWindow ? (
                                        <a href={item.href} className="block font-semibold text-gray-900" target="_blank" rel="noopener noreferrer">
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </a>
                                    ) : (
                                        <Link href={item.href} className="block font-semibold text-gray-900">
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </Link>
                                    )}
                                    <p className="mt-1 text-gray-600">{item.description}</p>
                                </div>
                            </div>
                            ))}
                        </div>                
                        </Popover.Panel>
                    </Transition>
                </Popover>

                <Popover className="relative">
                    <Popover.Button 
                        className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                        About
                        <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                        <div className="p-4">
                            {about.map((item) => (
                            <div
                                key={item.name}
                                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                            >
                                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                </div>
                                <div className="flex-auto">
                                
                                {item.newWindow ? (
                                    <a href={item.href} className="block font-semibold text-gray-900" target="_blank" rel="noopener noreferrer">
                                        {item.name}
                                        <span className="absolute inset-0" />
                                    </a>
                                ) : (
                                    <Link href={item.href} className="block font-semibold text-gray-900">
                                    {item.name}
                                    <span className="absolute inset-0" />
                                </Link>
                                )}
                                <p className="mt-1 text-gray-600">{item.description}</p>
                                </div>
                            </div>
                            ))}
                        </div>                
                        </Popover.Panel>
                    </Transition>
                </Popover>

            </Popover.Group>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                {/* <Link href="/api/auth/signin" className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
                </Link> */}

                {  
                    status === "authenticated" 
                    ? <ProfileMenu />
                    // ? <button className="text-sm font-semibold leading-6 text-gray-900" onClick={() => signOut()}>{session?.user?.email}</button>
                    : <Link href="/api/auth/signin?csrf=true" className="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></Link>
                }
            </div>      
        </nav>
        <div className="flex justify-center mb-5">
            <Breadcrumbs aria-label="breadcrumb">            
                {pathSegments
                // .filter(segment => !nonRoutablePages.includes(segment))
                .map((segment, index) => {
                    const breadcrumbPath = `/${pathSegments.slice(0, index + 1).join('/')}`;

                    if (nonRoutablePages.includes(segment)) {
                        //return null; // or return some default value
                        return (<Link color="inherit" className="text-xs leading-6 text-gray-900" href='' key={breadcrumbPath}>
                        {capitalizeFirstLetter(segment)}
                        </Link>)
                    }

                    return index === pathSegments.length - 1 ? (
                    //The final segment should not be a link
                    <Link color="inherit" className="text-xs leading-6 text-gray-900 font-semibold" href='' key={breadcrumbPath}>
                        {capitalizeFirstLetter(segment)}
                    </Link>
                    ) : (
                    //The intermediate segments should be links
                    <Link color="inherit" className="text-xs leading-6 text-gray-900" href={breadcrumbPath} key={breadcrumbPath}>
                        {capitalizeFirstLetter(segment)}
                    </Link>
                    );
                })}
            </Breadcrumbs>
        </div>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
            <Link href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Trevor Erickson</span>
                <img
                className="h-10 w-auto"
                src="/te-logo-512.png"
                alt=""
                />
            </Link>
            <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
            >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            </div>
            <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                        Playground
                        <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                        />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                        {[...playground].map((item) => (
                            <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                            {item.name}
                            </Disclosure.Button>
                        ))}
                        </Disclosure.Panel>
                    </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                        Info
                        <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                        />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                        {[...info].map((item) => (
                            <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                            {item.name}
                            </Disclosure.Button>
                        ))}
                        </Disclosure.Panel>
                    </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                    <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                        About
                        <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                        />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                        {[...about].map((item) => (
                            <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                            {item.name}
                            </Disclosure.Button>
                        ))}
                        </Disclosure.Panel>
                    </>
                    )}
                </Disclosure>                
                </div>
                <div className="py-6">

                        {/* <Link href="/api/auth/signin" className="text-sm font-semibold leading-6 text-gray-900">
                        Log in <span aria-hidden="true">&rarr;</span>
                        </Link> */}

                        {  
                            status === "authenticated" 
                            ? <ProfileMenu />
                            // ? <button className="text-sm font-semibold leading-6 text-gray-900" onClick={() => signOut()}>{session?.user?.email}</button>
                            : <Link href="/api/auth/signin?csrf=true" className="text-sm font-semibold leading-6 text-gray-900">Log in <span aria-hidden="true">&rarr;</span></Link>
                        }
  
                </div>
            </div>
            </div>
        </Dialog.Panel>
        </Dialog>
    </header>
    );
};

export default Header;